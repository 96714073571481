import { render, staticRenderFns } from "./index.vue?vue&type=template&id=243b2006&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=243b2006&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "243b2006",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FooterLogoSection: require('/codebuild/output/src1464236735/src/codestar-connections.us-west-2.amazonaws.com/git-http/384501692319/us-west-2/b627004c-6cc6-4388-b3d2-d28189087d6c/bode-canada/bode-client/components/footer/logo-section/index.vue').default,FooterNavigation: require('/codebuild/output/src1464236735/src/codestar-connections.us-west-2.amazonaws.com/git-http/384501692319/us-west-2/b627004c-6cc6-4388-b3d2-d28189087d6c/bode-canada/bode-client/components/footer/navigation/index.vue').default,FooterCopyright: require('/codebuild/output/src1464236735/src/codestar-connections.us-west-2.amazonaws.com/git-http/384501692319/us-west-2/b627004c-6cc6-4388-b3d2-d28189087d6c/bode-canada/bode-client/components/footer/copyright/index.vue').default,Footer: require('/codebuild/output/src1464236735/src/codestar-connections.us-west-2.amazonaws.com/git-http/384501692319/us-west-2/b627004c-6cc6-4388-b3d2-d28189087d6c/bode-canada/bode-client/components/footer/index.vue').default})
